.settingsContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}
.profileSection {
  text-align: center;
  margin-bottom: 20px;
  font-family: var(--client-font);
}

.profileSection p {
  margin-bottom: 10px;
  font-size: 16px;
  color: green;
  font-family: var(--client-font);
}

.addIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #fff;
  border-radius: 50%;
  color: #4caf50;
  font-size: 24px;
  cursor: pointer;
}

.inputGroup {
  margin-bottom: 20px;
}

.inputGroup label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: green;
  font-weight: 900;
  font-family: var(--client-font);
}
.profile {
  width: 80%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-weight: bold;
  white-space: wrap;
}
.inputGroup input {
  width: 80%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.warningLine{
  color: black;
  font-size: 10px;
}
.profileLinkContainer {
  display: flex;
  align-items: center;
}

.profileLinkContainer input {
  flex: 1;
}

.editIcon {
  margin-left: 10px;
  cursor: pointer;
  color: #4caf50;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
}
.modalContent h2 {
  color: green;
}
.inputField {
  width: 90%;
  padding: 8px;
  margin: 10px 0;
}

.okButton{
  padding: 8px 12px;
  margin: 5px;
  cursor: pointer;
  color: green;
  font-weight: bold;
}
.cancelButton {
  padding: 8px 12px;
  margin: 5px;
  cursor: pointer;
  color: green;
  font-weight: bold;
}
