.container {
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
}

.importantSection {
  margin-bottom: 30px;
  padding: 15px;
  border-radius: 8px;
  background-color: white; /* Light yellow background */
  font-family: var(--client-font);

}

.importantText {
  color: black; /* Darker yellow */
  font-size: 1.5em;
  font-family: var(--client-font);

}

.bellIcon {
  margin-left: 10px;
  color: green;
}

.startButton {
  color: green;
  padding: 10px 20px;
  border: none;
  font-size: 30px;
  margin: 20px 0;
  font-weight: bold;
  text-decoration: underline;
  background: white;
}