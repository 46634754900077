/* Container for all books */
.booksContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Grid for displaying book cards */
.bookGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 books per row on larger screens */
  gap: 50px;
}
.bookGrid h3 {
  color: green;
  font-size: 14px;
  font-family: var(--client-font);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis; /* Adds "..." if the content overflows */
  height: 10px; /* Approx. 2 lines of text height */
  white-space: normal;
  margin-top: 0px;
  padding-bottom: 7px;
}
.bookGrid p {
  font-size: 14px;
  font-family: var(--client-font);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis; /* Adds "..." if the content overflows */
  height: 2.4em; /* Approx. 2 lines of text height */
  white-space: normal;
}

/* Individual book card styling */
.bookCard {
  width: 220px;
  height: 430px; /* Set a fixed height for all book cards */
  background-color: white;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Book image styling */
.bookImage {
  width: 210px;
  height: 300px; /* Set a fixed height for the image */
  border-radius: 8px;
  align-self: center;
}
.line {
  font-size: 20px;
  color: green;
  font-family: var(--client-font);
}
.nav_link {
  color: green;
  border: none;
  font-size: 20px;
  text-decoration: underline; /* Removes underline from links */
  bottom: 0;
  margin-top: 100px;
  font-family: var(--client-font);
}

.nav_link:hover {
  color: red;
}
/* Pagination container */
.pagination {
  margin-top: 20px;
}

/* Start Certification Button */
.certificationButton {
  margin-top: 10px; /* Space above the button */
  padding: 10px 15px; /* Padding for the button */
  background-color: green; /* Green background */
  color: white; /* White text color */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  text-align: center; /* Center text */
  text-decoration: none; /* No underline */
  display: inline-block; /* Make it behave like a block element */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth background transition */
}
.disabledButton {
  background-color: #ccc; /* Disabled color (grey) */
  cursor: not-allowed; /* Not allowed cursor for disabled */
  color: #888; /* Disabled text color */
}

/* Pagination buttons */
.pageButton {
  padding: 10px 15px;
  margin: 0 5px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Disabled state for pagination buttons */
.pageButton:disabled {
  background-color: #ccc;
}
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  height: 100vh;
}

.loader {
  border: 8px solid white; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 70%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}
.IconContainer {
  display: flex;
  flex-direction: row;
  justify-content: right;
}
/* .cartIcon {
  font-size: 1.2rem;
  margin-top: 10px;
  cursor: pointer;
  color: white; /* Default color */
/* transition: color 0.3s ease;
  margin-right: 5px;
  text-align: right;
  background-color: green;
  border-radius: 10px; */
/* }  */

.Certified_text p {
  color: green;
  margin-top: 10px;
  font-size: 20px;
  font-weight: 400;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Modal Overlay */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto; /* Allow scrolling in case content is larger than the screen */
}

/* Modal Content - Centered */
.modalContent {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  max-width: 600px; /* Set a maximum width for the modal */
  height: 90%; /* Ensure it doesn't exceed the viewport height */
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  overflow-y: auto; /* Enable scroll for overflow */
  align-items: center;
}

/* Close Button */
.closeButton {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 25px;
  color: #333;
  cursor: pointer;
  font-weight: bold;
}

/* Modal Image */
.modalImage {
  width: 190px; /* Make the image responsive */
  max-width: 500px; /* Set a max-width for larger screens */
  height: 350px;
  margin-bottom: 20px;
}
.des {
  margin-top: 10px;
  color: black;
  font-size: large;
  font-weight: bold;
}
.title {
  color: green;
  text-transform: uppercase;
  font-weight: bold;
}
.key_takeaways {
  text-align: left;
  align-self: flex-start;
  color: black;
  font-weight: bold;
}
.modalContent strong {
  color: black;
  font-weight: bold;
}
.bubble_button {
  background-color: gray;
  color: white;
  font-weight: bold;
  border-radius: 30px;
  border: none;
  height: 40px;
  width: 300px;
  margin: 20px;
}
.bubble_button.active {
  background-color: green;
  color: white;
}

.bubble_button.active::after {
  content: "";
  background-color: green;
  color: white;
}
@media (max-width: 768px) {
  .bookGrid {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* 2 books per row on medium screens */
  }

  .bookCard {
    width: 240px;
    height: 430px; /* Slightly smaller for medium screens */
  }

  .bookImage {
    width: 230px;
    height: 350px; /* Adjust image height proportionally */
  }

  .pagination {
    margin-top: 15px;
  }

  /* Adjust modal content size for medium screens */
  .modalContent {
    max-width: 90%; /* Smaller modal on medium screens */
    max-height: 80%;
  }

  .modalImage {
    max-width: 300px;
  }
}

/* Small screens (phones) */
@media (max-width: 480px) {
  .bookGrid {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* 2 books per row on medium screens */
    gap: 10px;
  }

  .bookCard {
    width: 160px;
    height: 330px; /* Slightly smaller for medium screens */
  }

  .bookImage {
    width: 130px;
    height: 250px; /* Adjust image height proportionally */
  }
  .pagination {
    margin-top: 10px;
  }

  .pageButton {
    padding: 8px 12px; /* Smaller button padding */
  }

  /* Adjust modal content size for small screens */
  .modalContent {
    max-width: 90%; /* Use nearly full width for small screens */
    max-height: 70%;
  }

  .modalImage {
    max-width: 100px;
    margin-top: 100px; /* Reduce image size for small screens */
  }
  .Certified_text p {
    color: green;
    margin-top: 10px;
    font-size: 15px;
    font-weight: 400;
  }
  .bubble_button {
    background-color: white;
    color: green;
    font-weight: bold;
    border-radius: 30px;
    border: none;
    height: 40px;
    width: 160px;
    margin: 10px;
  }
}
