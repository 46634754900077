/* Container for the entire page layout */
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: Arial, sans-serif;
  background: #f9f9f9;
}
.topStrip {
  display: flex;
  justify-content: center;
  background-color: #c5cac6;
}
.topStrip a {
 color: black;
 font-weight: bold;
}
.top_bar {
  display: flex;
  background: #f9f9f9;
}
.top_bar1 {
  flex: 1;
  width: 30%;
}

.top_bar2 {
  display: flex;
  flex: 2;
  justify-content: space-around;
  width: 70%;
}

/* Navigation link styles */
.nav_link {
  color: green;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  text-decoration: underline;
  font-family: var(--client-font);
  white-space: nowrap;
}
.top_bar_logo {
  display: flex;
  flex-direction: row;
}
.top_bar_logo h1 {
  font-size: 45px;
  font-weight: 900;
  margin-left: 20px;
}
.experimental {
  font-size: 10px;
  margin-top: 40px;
  border-radius: 10px; /* Reduced radius */
  border: 1px solid black;
  padding: 5px 10px;
  display: flex;
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
  height: 10px; /* Set an explicit height to control the container size */
  line-height: normal; /* Ensure line-height doesn't interfere */
}
.shareWidget {
  position: absolute;
  width: 40%;
  top: 18%;
  left: 65%;
  transform: translate(-50%, -50%);
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 0px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
}
.disabled {
  color: #aaa; /* Adjust color for disabled state */
  cursor: not-allowed;
  opacity: 0.6; /* Optional: make the button semi-transparent */
}

.shareButtonsContainer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.shareButton {
  width: 100px;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  background-color: #f0f0f0;
  color: #000;
  text-decoration: none;
  font-size: 14px;
}
.shareButton p {
  margin-top: 0px;
  color: black;
}

.shareButton:hover {
  background-color: #e0e0e0;
}

.shareWidgetHeader {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 0px;
}

.closeIcon {
  font-size: 1.2rem;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease;
}

.closeIcon:hover {
  color: white;
}
.copyIcon {
  font-size: 20px;
  color: white;
  cursor: pointer;
  transition: color 0.3s ease;
  border-radius: 10px;
}
.copyIcon:active,
.copyIcon:focus,
.copyIconDiv:hover .copyIcon {
  color: white; /* Remains white when active, focused, or hovered */
}
.copyIconDiv{
  margin-right: 15px;
  width: 50px;
  justify-items: center;
}
.copyIconDiv p{
  margin-top: 5px;
  white-space: nowrap;
}
.copyIcon:hover {
  color: darkgreen;
}
.copyIconContainer{
  background: green;
  padding: 4px;
  border-radius: 100px;
  width: 25px;
}
.nav_link_knowela {
  background: transparent;
  border: none;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  
}
.nav_link_knowela img {
  height: 50px;
  width: auto;
  margin-left: 20px;
  margin-top: 20px;
}

.nav_link_share {
  background: transparent;
  color: green;
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  padding: 10px;
  text-decoration: underline;

}

.nav_link:hover {
  color: red;
}
/* Footer styles */
.footer {
  background: #f9f9f9;
  text-align: center;
  color: green;
  font-size: 22px;
  padding: 10px 0;
  font-weight: bold;
  margin-top: auto;
}

/* Responsive styling */
@media (max-width: 1024px) {
  /* Adjustments for tablets and smaller laptops */
  .top_bar {
    flex-direction: row;
    align-items: center;
  }
  .top_bar1 h1 {
    font-size: 40px;
    font-weight: 900;
    margin-left: 18px;
  }
  .nav_link,
  .nav_link_share {
    font-size: 18px; /* Reduce font size */
    margin-bottom: 10px; /* Add spacing between links */
  }
}

@media (max-width: 768px) {
  /* Adjustments for tablets and smaller devices */
  .top_bar {
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    padding: 10px;
  }
  .top_bar1 {
    width: 100%; /* Make the header full width */
    text-align: center; /* Center align the text */
  }
  .top_bar_logo h1 {
    font-size: 20px;
    font-weight: 900;
    margin-left: 0; /* Center the title */
  }
  .top_bar_logo {
    text-align: center;
    justify-content: center;
  }
  .experimental {
    margin-top: 16px;
    font-size: 6px;
    border-radius: 10px;
    border: 1px solid black;
    padding: 3px;
  }
  .top_bar2 {
    width: 100%; /* Full width for navigation */
    justify-content: center; /* Center the navigation */
    gap: 20px; /* Add some spacing between navigation items */
  }
  .nav_link,
  .nav_link_share {
    font-size: 14px; /* Further reduce font size */
    margin-bottom: 8px;
  }
  .footer {
    font-size: 20px; /* Adjust footer text size */
    padding: 8px 0;
  }
}

@media (max-width: 480px) {
  /* Adjustments for mobile devices */
  .top_bar {
    flex-direction: column; /* Stack items vertically */
    justify-content: center;
    padding: 5px;
  }
  .top_bar1 {
    width: 100%; /* Full width */
    text-align: center; /* Center align */
  }
  .top_bar_logo {
    text-align: center;
    justify-content: center;
  }
  .nav_link_knowela img {
    height: 30px;
    margin-top: 20px;
  }
  .experimental {
    margin-top: 30px;
    font-size: 6px;
    border-radius: 10px;
    border: 1px solid black;
    padding: 3px;
  }
  .top_bar_logo h1 {
    font-size: 16px;
    font-weight: 900;
    margin-left: 0;
  }
  
  .top_bar2 {
    width: 100%; /* Full width for navigation */
    justify-content: center; /* Center align items */
    gap: 15px; /* Space out navigation links */
  }
  .nav_link,
  .nav_link_share {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .footer {
    font-size: 18px;
    padding: 5px 0;
  }
  .shareWidget {
    position: absolute;
    top: 35%;
    left: 65%;
    width: 30%;
    transform: translate(-50%, -50%);
    background: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    margin: 0px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
  }
  .copyIconContainer{
    background: green;
    padding: 4px;
    border-radius: 100px;
    width: 25px;
    margin-left: 15px;
  }
  .copyIconDiv p{
    margin-left: 8px;
  }
}
