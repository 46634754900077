/* Base Styles */
.container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.topBar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  padding: 10px 0;
  margin-bottom: 30px;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}
.topBar span {
  color: green;
  font-weight: bold;
}

.button {
  background-color: #fff;
  border: none;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: #333;
  margin: 10px; /* Ensures spacing between buttons */
  font-family: var(--client-font);

}

.button span {
  margin-top: 5px;
}

.button:hover {
  color: #007bff;
}

.content {
  flex: 1;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  font-family: var(--client-font);

}

h2 {
  font-size: 20px;
  color: #333;
  margin-bottom: 15px;
  font-family: var(--client-font);

}

h3 {
  font-size: 20px;
  color: #333;
  margin-top: 25px;
  font-family: var(--client-font);

}

p {
  font-size: 20px;
  color: black;
  line-height: 1.6;
  margin-bottom: 15px;
  font-family: var(--client-font);

}

ul {
  list-style-type: disc;
  padding-left: 40px;
  color: black;
  margin-bottom: 15px;
}

ul li {
  font-size: 20px;
  margin-bottom: 10px;
  font-family: var(--client-font);

}

/* Responsive Design */

/* For tablets and smaller screens (max-width: 768px) */
@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .topBar {
    justify-content: center;
  }
  
  .button {
    font-size: 14px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  p,
  ul li {
    font-size: 16px;
  }
}

/* For mobile screens (max-width: 480px) */
@media (max-width: 480px) {
  .container {
    padding: 5px;
  }

  .button {
    font-size: 12px;
  }

  .content {
    padding: 15px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 18px;
  }

  p,
  ul li {
    font-size: 14px;
  }
}
