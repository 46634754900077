/* Center the login container vertically and horizontally */
.loginContainer {
  flex: 1;
  justify-content: center;
  align-self: center;
  align-items: center;
  align-content: center;
  text-align: center;
  background-color: white; /* Optional: light background */
}

/* Bold and center the heading */
.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: green;
  font-family: var(--client-font);

}

/* Margin for the Google button area */
.googleButton {
  margin-top: 100px;
}
.googleButton h1 {
  color: green;
  font-family: var(--client-font);

}

/* Styles for the input field */
.inputField {
  width: 90%; /* Responsive width, adjust based on screen size */
  max-width: 300px; /* Max width to prevent it from getting too wide */
  padding: 12px; /* Add some padding for better usability */
  margin: 10px 0; /* Vertical margin for spacing */
  border-radius: 5px; /* Rounded corners */
  border: 2px solid green; /* Green border */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  font-size: 16px; /* Font size for better readability */
  font-family: var(--client-font);

}

/* Styles for the continue button */
.continueButton {
  width: 90%; /* Same responsive width as the input field */
  max-width: 300px; /* Same max width */
  padding: 12px; /* Add padding for a comfortable click area */
  background-color: green; /* Green background */
  color: white; /* White text color */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 16px; /* Match font size with input field */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  margin-top: 10px; /* Add spacing above the button */
  font-family: var(--client-font);

}

/* Button hover effect */
.continueButton:hover {
  background-color: #45a049; /* Darker green on hover */
}

/* Optional: Focus styles for accessibility */
.inputField:focus {
  border-color: #4caf50; /* Green border on focus */
  outline: none; /* Remove default outline */
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5); /* Glow effect */
}
.customGoogleButton {
  display: inline-block;
  width: 90%;
  text-align: center;
  margin-top: 20px;
}

/* Styling the default Google button to make it more custom-looking */
.customGoogleButton > div {
  display: inline-block;
  background-color: green; 
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.customGoogleButton > div:hover {
  background-color: #357ae8; /* Darker shade of blue on hover */
}
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  height: 100vh;
}

.loader {
  border: 8px solid white; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 70%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .heading {
    font-size: 20px; /* Smaller font size for smaller screens */
  }

  .inputField,
  .continueButton {
    width: 90%; /* Keep the input field and button responsive */
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 18px; /* Further reduce font size on extra small screens */
  }

  .inputField,
  .continueButton {
    padding: 10px; /* Slightly smaller padding */
  }
}
