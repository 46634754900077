/* General Container Styling */
.questions_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
}

/* Certificate Info Section */
.certificate_info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.quiz_line{
  font-weight: bold;
  background: white;
  width: 100%;
  text-align: center;
  padding: 10px;
  font-family: var(--client-font);

}
.line{
  color: green;
  font-size: larger;
  font-family: var(--client-font);

}
.certificate_text {
  color: green;
  font-size: 1.2em;
  font-weight: bold;
  font-family: var(--client-font);

}

.copyIcon {
  cursor: pointer;
  margin-left: 10px;
  color: green;
}

.copyIcon:hover {
  color: darkgreen; /* Improved hover state */
}

/* Question Section */
.question_content {
  width: 100%;
  max-width: 600px;
  border: 5px solid green;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
}

.question_title {
  text-align: left;
  font-size: 1.5em;
  font-weight: bold;
  color: green;
  font-family: var(--client-font);

}

.question_text {
  text-align: left;
  font-size: 1.2em;
  margin-bottom: 20px;
  color: black;
  font-family: var(--client-font);
}

/* Answer Options */
.answers_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.answer_option {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid green;
  border-radius: 5px;
  cursor: pointer;
}

.answer_option:hover {
  background-color: #f0f9f0; /* Add hover state for answer options */
}

.answer_option input {
  margin-right: 10px;
  cursor: pointer;
}

/* Timer Section */
.timer {
  text-align: center;
  margin-top: 10px;
  font-size: 1.1em;
}

/* Next Button */
.next_button {
  width: 100%;
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
  font-size: 1.2em;
  cursor: pointer;
  font-family: var(--client-font);

}

.next_button:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.next_button:hover:not(:disabled) {
  background-color: darkgreen;
}
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  height: 100vh;
}

.loader {
  border: 8px solid white; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 70%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive Design */

/* Tablet and Mobile Adjustments */
@media (max-width: 768px) {
  .question_content {
    width: 90%;
    padding: 15px; /* Adjust padding for smaller screens */
    font-size: 1em; /* Reduce font size */
  }

  .certificate_text {
    font-size: 15px;
  }
  .question_title {
    font-size: 1.3em; /* Slightly smaller title */
  }

  .question_text {
    font-size: 1.1em;
  }

  .next_button {
    font-size: 1.1em;
    padding: 8px; /* Adjust button size */
  }
}

/* Small mobile devices (below 480px) */
@media (max-width: 480px) {
  .question_content {
    width: 95%;
    padding: 10px;
  }

  .certificate_text {
    font-size: 10px;
  }

  .question_title {
    font-size: 1.2em;
  }

  .question_text {
    font-size: 1em;
    margin-bottom: 15px;
  }

  .answer_option {
    padding: 8px; /* Reduce padding for small screens */
  }

  .timer {
    font-size: 1em;
  }

  .next_button {
    font-size: 1em;
    padding: 8px; /* Smaller button padding */
  }
}

/* Large screens (desktops above 1200px) */
@media (min-width: 1200px) {
  .question_content {
    max-width: 800px; /* Wider content for large screens */
  }

  .question_title {
    font-size: 1.8em;
  }

  .question_text {
    font-size: 1.4em;
  }

  .next_button {
    font-size: 1.4em;
    padding: 12px;
  }
}
