/* General Styles */
.share_container {
  text-align: center;
  margin: 20px auto;
  max-width: 1200px;
  padding: 0 20px;
  font-family: var(--client-font);
}

/* Form Styling */
.share_form label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: var(--client-font);
}

.share_form input {
  width: 100%;
  max-width: 400px;
  padding: 8px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Book Scroll and Arrow Buttons */
.bookScrollContainer {
  display: flex;
  overflow-x: auto;
  padding: 20px;
  gap: 20px;
  scroll-behavior: smooth;
  max-width: 100%;
}

.bookScrollContainer::-webkit-scrollbar {
  display: none;
}

.scrollButton {
  color: black;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 30px;
  align-self: center;
}

.scrollButton:hover {
  color: green;
}

.scrollButton:first-of-type {
  left: 0;
}

.scrollButton:last-of-type {
  right: 0;
}

.books_container {
  flex: 1;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.book_scroll {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 5px;
}

.book_scroll::-webkit-scrollbar {
  display: none;
}

.book_card {
  flex: none;
  height: 130px;
  width: 100px;
  border-radius: 10px;
  scroll-snap-align: start;
  font-family: var(--client-font);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.book_card:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
}

.book_card.selected {
  border: 4px solid darkgreen; /* Highlight border for selected book */
  box-shadow: 0 6px 15px rgba(7, 18, 7, 0.5); /* More prominent shadow for selection */
}
.book_image,
.book_image1 {
  width: 100%;
  height: 130px;
  background: transparent;
}

.book_image1 {
  border: 3px dashed green;
}

.plusIcon {
  font-size: 40px;
  color: green;
  font-family: var(--client-font);
  margin-bottom: 5px;
}

.addText {
  font-size: 16px;
  color: green;
  font-family: var(--client-font);
}

/* Certificates */
.certificate_wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 10px;
  background-color: white;
}

.certificate_card,
.certificate_card1 {
  justify-content: center; /* Ensures vertical alignment */
  align-items: center;
  width: 100%;
  max-width: 792px;
  height: 297mm;
  max-height: 297mm;
  background-color: #fff8df;
  position: relative;
  padding: 0;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.leaders_are_readers {
  height: 7mm; /* Avoid auto height on iOS */
  line-height: 7mm; /* Consistent vertical centering */
  width: 100%;
  font-size: 17px;
  color: #965514;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  font-family: var(--client-font);
  align-content: center;
  padding: 0px;
  margin-top: 0px;
  background-color: #fbbd1d;
}

.Certificate_of_knowledge {
  height: 45mm;
  font-size: 30px;
  margin: 0;
  padding-top: 20px;
  white-space: wrap;
  font-family: var(--client-font);
  align-content: flex-start;
}
.Congratulation {
  height: 15mm;
  width: 100%;
  font-size: 30px;
  align-content: center;
  margin: 0;
  padding: 0;
  font-family: var(--client-font);
}
.Cert_Username {
  height: 15mm;
  width: 100%;
  font-size: 30px;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-content: center;
  font-family: var(--client-font);
}
.two_line_content {
  height: 70mm;
  overflow: hidden;
  align-content: center;
  font-size: 30px;
  font-weight: 200;
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  padding: 10px;
  color: black;
  font-family: var(--client-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
.top_skills {
  height: 30mm;
  font-size: 30px;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-content: center;
  font-family: var(--client-font);
}

.Grid_container {
  height: 50mm;
  display: flex;
  justify-content: center;
  font-family: var(--client-font);
}

.skills_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5em; /* Scalable gap */
  margin: 0;
  padding: 0;
  height: max-content;
  font-family: var(--client-font);
}

.skills_grid li {
  list-style-type: none;
  font-size: 15px;
  border: 1px solid;
  max-width: 220px;
  width: 220px;
  /* min-height: min-content; */
  height: auto;
  /* max-height: min-content; */
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  white-space: normal; /* Allow wrapping to prevent overflow */
  margin: 4px;
  align-items: center;
  font-family: var(--client-font);
  display: flex; /* Ensures height adjusts to content */
  justify-content: center;
}
.logo_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 67mm;
  width: 100%;
  margin-bottom: 10px;
}

.logo_container img {
  width: 200px;
  height: 290px;
}

.logo_container p {
  font-size: 15px;
  margin: 0;
  padding: 0;
  color: #333;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  font-family: var(--client-font);
}
.fake_text {
  opacity: 0.1;
  font-size: 24px;
  color: black;
  transform: rotate(-30deg);
  user-select: none;
  font-family: var(--client-font);
}

.watermark_grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  pointer-events: none;
}

/* Logo Container */

/* Buttons */
.download_btn {
  font-size: 16px;
  width: 30%;
  font-weight: bold;
  background-color: white;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
  padding: 10px;
  height: 40px;
  align-self: right;
  border-radius: 20px;
  font-family: var(--client-font);
}

.download_btn:hover {
  background-color: #0056b3;
}
.Cert_button {
  display: flex;
  flex-direction: row;
  background: #cfebdb;
  height: 60px;
  justify-content: center;
  width: 70%;
  justify-self: center;
  border-radius: 10px;
  margin-bottom: 0;
}
.limited_time_offer {
  margin-top: 0;
  font-size: 12px;
  color: #333;
  font-family: var(--client-font);
}
.loadingContainer {
  position: fixed; /* Ensure it takes the whole viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center; /* Vertically center */
  justify-content: center; /* Horizontally center */
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optional: Add a semi-transparent background */
  z-index: 9999; /* Ensure it's above all other elements */
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid green; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.Cert_button_text {
  font-size: 12px;
  margin-bottom: 0;
  font-weight: bold;
  color: black;
  text-decoration: line-through;
  font-family: var(--client-font);
}
.Cert_button_text1 {
  font-size: 12px;
  margin-top: 0px;
  font-weight: bold;
  color: green;
  font-family: var(--client-font);
}
.Cert_button_text2 {
  width: 65%;
  text-align: center;
  justify-content: center;
  font-family: var(--client-font);
}
.bottom {
  background: white;
  margin-top: 80px;
  padding: 30px;
}

.bottom h4 {
  color: green;
  font-weight: bold;
  font-family: var(--client-font);
}
.bottom h3 {
  margin: 0;
}
.bottom p {
  color: green;
}
@supports (-webkit-touch-callout: none) {
  .leaders_are_readers {
    margin-top: 0 !important;
  }

  .certificate_card,
  .certificate_card1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .Certificate_of_knowledge,
  .Congratulation,
  .Cert_Username {
    margin: 0 auto;
    padding: 5px 0;
  }
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .certificate_card,
  .certificate_card1 {
    flex: 1 1 45%;
    max-width: 100%;
    height: auto;
  }

  .skills_grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3px;
    margin: 1px auto;
  }

  .skills_grid li {
    font-size: 10px;
    border: 1px solid;
    padding: 1px;
    border-radius: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 3px;
  }
}

@media (max-width: 768px) {
  .certificate_wrapper {
    flex-direction: column;
    align-items: center;
  }

  .certificate_card,
  .certificate_card1 {
    padding: 0px;
    height: 500px;
    max-width: 500px;
  }
  .skills_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3px;
    margin: 1px auto;
  }

  .skills_grid li {
    list-style-type: none;
    font-size: 12px;
    border: 1px solid;
    padding: 4px;
    width: 150px;
    border-radius: 5px;
    white-space: wrap;
  }
}

/*** IPHONE 11***/

@media (max-width: 480px) {
  .share_form input {
    max-width: 100%;
  }

  .certificate_card,
  .certificate_card1 {
    padding: 0px;
    height: 130mm;
    max-width: 300px;
  }

  .leaders_are_readers {
    font-size: 8px;
    height: 4mm;
    line-height: 4mm;
    padding-top: 3px;
  }

  .Certificate_of_knowledge {
    font-size: 12px;
    padding-top: 10px;
    height: 20mm;
  }
  .Congratulation {
    font-size: 12px;
    height: 10mm;
  }
  .Cert_Username {
    font-size: 12px;
    height: 10mm;
  }
  .two_line_content {
    font-size: 12px;
    color: black;
    height: 30mm;
  }
  .top_skills {
    font-size: 12px;
    height: 10mm;
  }
  .Grid_container {
    height: 30mm;
  }
  .skills_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3px;
    margin: 1px auto;
    height: max-content;
  }
  .skills_grid li {
    font-size: 6px;
    max-width: 80px;
    height: auto;
    text-align: center;
    white-space: normal;
  }
  .logo_container {
    height: 16mm;
    width: 100%;
  }
  .logo_container img {
    width: 70px;
    height: 100px;
  }

  .logo_container p {
    font-size: 8px;
  }
  .Cert_button {
    display: flex;
    background: #cfebdb;
    height: 60px;
    justify-content: center;
    width: 100%;
    justify-self: center;
    border-radius: 10px;
    margin-bottom: 30px;
  }
  .download_btn {
    font-size: 12px;
    padding: 0;
  }
  .Cert_button_text2 {
    width: 65%;
    text-align: center;
    justify-content: center;
  }
}
