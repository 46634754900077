/* Container for the entire results section */
.results_container {
  display: flex;
  min-height: 100vh;
  font-family: var(--client-font);
  flex-direction: column;
}

/* Styling for the results title */
.results_container h1 {
  font-size: 35px; /* Large and prominent font size */
  color: green; /* Dark text color */
  text-align: center;
  font-weight: bold;
  margin-top: 0;
  padding: 10px;
}

/* Styling for the obtained marks text */
.results_container p {
  font-size: 35px;
  color: green; /* Green color to emphasize success */
  text-align: center;
  margin-top: 0;
  padding-left: 10px;
  padding-right: 10px;


}
.button {
  color: green;
  font-size: 30px;
  width: 100px;
  align-self: center;
  font-weight: bold;
}
/* Responsive Design - Adjustments for smaller screens */
@media (max-width: 768px) {
  .results_container h1 {
    font-size: 30px; /* Slightly smaller title for mobile */
  }

  .results_container p {
    font-size: 30px; /* Smaller text for mobile */
  }
}
@media (max-width: 480px) {
  .results_container h1 {
    font-size: 20px; /* Slightly smaller title for mobile */
  }

  .results_container p {
    font-size: 20px; /* Smaller text for mobile */
  }
}
