/* global.css */
:root {
  --client-font: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; /* Your client's font */
}

body {
  font-family: var(--client-font);
  margin: 0; /* Reset default margin */
  padding: 0; /* Reset default padding */
}
html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%; /* Prevent iOS font scaling */
}


/* Top Bar */
.topBar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  padding: 10px 0;
  margin-bottom: 30px;
  flex-wrap: wrap;
}
.topBar span{
  color: green;
  font-weight: bold;
}
/* Button Styling */
.button {
  background-color: #fff;
  border: none;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: #333;
  margin: 10px;
  text-align: center;
  font-family: var(--client-font);
}

.button span {
  margin-top: 5px;
}

.button:hover {
  color: #007bff;
}

.button.active {
  color: green; /* Highlight active text color */
}
.button.active::after {
  content: "";
  display: block;
  width: 50%;
  height: 2px;
  background-color: green; /* Line color */
  position: absolute;
  bottom: 0;
  left: 3;
}
/* Content Area */
.content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.content h1 {
  font-size: 20px;
  margin-bottom: 20px;
  font-family: var(--client-font);

}

.content p {
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 15px;
  font-family: var(--client-font);

}

.bottom {
  margin-top: 40px;
}


/* Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .topBar {
    justify-content: center;
  }

  .button {
    font-size: 14px;
  }
  
  .content h1 {
    font-size: 20px;
  }

  .content p {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 5px;
  }

  .button {
    font-size: 12px;
  }

  .content h1 {
    font-size: 18px;
  }

  .content p {
    font-size: 13px;
  }
}

