
  /* Container */
  .dashboard_container {
    padding: 20px;
    margin: 0 auto;
    max-width: 1200px;
  }
/* Base Styles */
.image {
    width: auto; /* Make the image take full width of the card */
    height: 400px; /* Maintain aspect ratio */
    border-radius: 5px;
  }
.button.active {
    color: green; /* Highlight active text color */
  }
.button.active::after {
    content: "";
    display: block;
    width: 50%;
    height: 2px;
    background-color: green; /* Line color */
    position: absolute;
    bottom: 0;
    left: 3;
  }

  .content{
    justify-content: center;
    text-align: center;
  }
  .content h4 {
      color: green;
      font-weight: bold;
      text-align: center;
      font-family: var(--client-font);
  }
  .content h3 {
    margin: 0;
  }
  .content p {
      color: green;
      text-align: center;
      font-size: 20px;  font-family: var(--client-font);
  }

  /* Responsive Design */
@media (max-width: 768px) {
    .image {
      width: 300px; /* Make the image take full width of the card */
      height: 400px; /* Maintain aspect ratio */
      border-radius: 5px;
    }
  }
  @media (max-width: 480px) {
    .image {
      width: 370px; /* Make the image take full width of the card */
      height: 250px; /* Maintain aspect ratio */
      border-radius: 5px;
    }
  }