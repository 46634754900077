/* App container */
.app_container {
  display: flex;
  height: 87vh; /* Full viewport height */
  font-family: Arial, sans-serif;
  padding: 0 20px;
}
.loadingContainer {
  position: fixed; /* Ensure it takes the whole viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center; /* Vertically center */
  justify-content: center; /* Horizontally center */
  background-color: rgba(0, 0, 0, 0.5); /* Optional: Add a semi-transparent background */
  z-index: 9999; /* Ensure it's above all other elements */
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid green; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* First container (text area) */
.con1 {
  flex: 1;
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

/* Second container (image area) */
.con2 {
  flex: 2;
  width: 70%;
  height: 100%;
  position: relative; /* To control the image positioning within this container */
}

.image {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 800px;
  object-fit: cover; /* Ensures the image covers the container without stretching */
}

/* Text column styles */
.text_column {
  text-align: left;
}

.text_column p {
  font-size: 16px;
  color: green;
  margin: 0;
  font-family:var(--client-font);
}

.text_column p.text {
  font-size: 40px;
  color: black;
  font-weight: bold;
  margin: 0;
  font-family: var(--client-font);
}
.text_column p.text .green {
  color: green; /* Green color for specific words */
}

.text_column p.text strong {
  font-weight: bold; /* Bold for the BOOKS and READ */
}
.text_column p.text1 {
  font-size: 20px;
  color: green;
  font-family: var(--client-font);
}

.margin {
  margin-top: 40px;
}

.text_column h1 {
  color: green;
  font-size: 40px;
  font-family: var(--client-font);
  margin-bottom: 20px;
}

/* Search bar container */
.search_bar_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.input_with_icon form {
  display: flex;
  align-items: center;
}
.input_with_icon {
  position: relative;
  width: 100%;
  max-width: 800px;
}
.input_with_icon .icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%); /* Center the icon vertically */
  color: gray;
  font-size: 18px; /* Adjust icon size */
  pointer-events: none; /* Icon won’t block input interactions */
  margin-right: 5px;
}
.input_with_icon input {
  padding: 7px;
  font-size: 20px;
  width: 90%;
  height: 30px;
  max-width: 800px;
  border: 1.2px solid black;
  border-radius: 20px;
  font-family: var(--client-font);
  text-align: center;
}

/* Recommendations link */
.recommendations_link {
  display: block;
  margin-top: 20px;
  text-decoration: none;
  color: black;
  font-weight: bold;
  text-align: left;
  text-decoration: underline;
  font-size: 20px;
  font-family: var(--client-font);
  margin-left: 30px;
  background-color: transparent;
  border: none;
}

.recommendations_link:hover {
  text-decoration: underline;
  color: darkgreen;
}
.customGoogleButton {
  display: flex;
  width: 100%;
  margin-top: 100px;
  margin-left: 0;
  justify-content: center;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .app_container {
    flex-direction: column;
    height: auto;
  }

  .con1, .con2 {
    width: 100%;
    height: auto;
  }

  .con2 {
    height: 50vh; /* Reduce height for smaller screens */
  }

  .text_column h1 {
    font-size: 40px; /* Reduce the size of h1 */
  }

  .text_column p.text {
    font-size: 32px;
  }

  .text_column p.text1 {
    font-size: 18px;
  }

  .image {
    position: relative;
    height: 100%;
    object-fit: cover;
    margin-top: 10px; /* Ensures the image fits perfectly */

  }
}

@media (max-width: 768px) {
  .app_container {
    flex-direction: column;
    height: auto;
  }

  .con1, .con2 {
    width: 100%;
    height: 50vh;
  }

  .con2 {
    height: 50vh; /* Keep the height of the image consistent */
  }

  .text_column h1 {
    font-size: 30px; /* Further reduce the size of h1 */
    margin-top: 30px;
  }

  .text_column p.text {
    font-size: 28px;
  }

  .text_column p.text1 {
    font-size: 16px;
  }

  .search_bar_container input {
    width: 100%;
    font-size: 18px;
  }

  .recommendations_link {
    font-size: 18px;
    text-align: center;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    margin-top: 10px; /* Ensures the image fits perfectly */
  }
}

@media (max-width: 480px) {
  .app_container {
    display: flex;
    flex-direction: column;
  }

  .con1, .con2 {
    width: 100%;
    height: 100vh;
  }

  .text_column h1 {
    font-size: 24px; /* Further reduce for mobile screens */
    margin-top: 20px;
  }

  .text_column p.text {
    font-size: 20px;
  }

  .text_column p.text1 {
    font-size: 14px;
  }

  .search_bar_container input {
    width: 95%;
    font-size: 16px;
  }

  .recommendations_link {
    font-size: 16px;
    text-align: left;
    margin-left: 35px;
  }

  .image {
    width: 100%;
    height: 550px;
    object-fit: cover;
    position: relative;
    margin-top: 20px; /* Ensures the image scales down properly */
  }
}
