/* Container */
.container {
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px;
}

/* Top Bar */
.topBar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  padding: 10px 0;
  margin-bottom: 30px;
  flex-wrap: wrap;
}
.topBar span{
  color: green;
  font-weight: bold;
}
/* Button Styling */
.button {
  background-color: #fff;
  border: none;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: #333;
  margin: 10px;
  text-align: center;
  font-family: var(--client-font);
}

.button span {
  margin-top: 5px;
}

.button:hover {
  color: #007bff;
}

/* Content Area */
.content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.content h1 {
  font-size: 20px;
  margin-bottom: 20px;
  font-family: var(--client-font);

}

.content p {
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 15px;
  font-family: var(--client-font);

}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .topBar {
    justify-content: center;
  }

  .button {
    font-size: 14px;
  }
  
  .content h1 {
    font-size: 20px;
  }

  .content p {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 5px;
  }

  .button {
    font-size: 12px;
  }

  .content h1 {
    font-size: 18px;
  }

  .content p {
    font-size: 13px;
  }
}
